import Http from "@/services/Http";
export default {
  checkMembershipStatus() {
    return Http().post("nest/membership/status");
  },
  saveMembership(data) {
    return Http().post("nest/membership/save", data);
  },
  getFaqs(data) {
    return Http().post("/faqs", data);
  },
  getMembershipContent() {
    return Http().post("nest/membership/content");
  },
  getMembershipDetail() {
    return Http().post("nest/membership/details");
  },
  getPurchaseDetails(data) {
    return Http().post("nest/purchase/membership/details", data);
  }
};
